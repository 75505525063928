<template>
  <section class="relative home-hero-bg wrapper" :style="{'background-image': 'url(' + require('@/images/tc/hero2.jpg') + ')'}" style="background-size: cover;">

<!--    <div class="snow layer1 a"></div>-->
<!--    <div class="snow layer1"></div>-->
<!--    <div class="snow layer2 a"></div>-->
<!--    <div class="snow layer2"></div>-->
<!--    <div class="snow layer3 a"></div>-->
<!--    <div class="snow layer3"></div>-->

    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 pb-12 md:pt-40 md:pb-20">
        <div class="text-center pb-12 md:pb-16">
          <img class="max-w-2xl mx-auto mb-8" data-aos="zoom-y-out" :src="require('@/images/tc/logo.svg')" alt="Logo" />

          <div class="max-w-4xl mx-auto">
            <p class="text-xl font-bold text-center text-blue-800 mb-2" data-aos="zoom-y-out" data-aos-delay="150">
              BIM folyamatok kezelése és együttműködés egyszerűen a közös adatkörnyezetben (CDE)
              bárhol és bármikor – online és offline.
            </p>
            <p class="text-xl text-center text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
              A Trimble Connect egy felhőalapú modern közös adatkörnyezet (CDE) és együttműködési platform, amely összekapcsolja a megfelelő embereket a megfelelő adatokkal a megfelelő időben, bármely eszközön.
              Az információmegosztó eszköz a BIM projektek adatait valós időben megosztja a beruházók, kivitelezők, tervező mérnökök, építési vállalkozók és döntéshozók között. Időt takarít meg, csökkenti a költségeket, és növeli a szakterületeken átívelő együttműködés hatékonyságát.
            </p>
            <div class="w-full sm:max-w-full sm:flex justify-center" data-aos="zoom-y-out" data-aos-delay="300">
              <div><a class="btn text-white bg-blue-800 hover:bg-blue-700 w-full mx-auto mb-4 md:mb-0 sm:w-auto sm:mb-0" href="https://www.youtube.com/watch?v=j-SpSKAMsCI" @click.prevent.stop="videoModalOpen = true" aria-controls="modal"><i class="fas fa-play-circle mr-2"></i> Funkció áttekintése</a></div>
              <!-- <div><a class="btn text-white bg-blue-800 hover:bg-blue-700 w-full mx-auto mb-4 md:mb-0 sm:w-auto sm:ml-4" href="#0" @click.prevent.stop="newsletterModalOpen = true" aria-controls="modal"><i class="far fa-envelope mr-2"> </i>Newsletter</a></div> -->
              <div><a href="https://www.construsoft.com/hu/news" target="_blank" class="btn text-white bg-blue-800 hover:bg-blue-700 w-full mx-auto mb-4 md:mb-0 sm:w-auto sm:ml-4"><i class="far fa-newspaper mr-2"></i> Hírek</a></div>
              <div><a class="btn text-white bg-blue-800 hover:bg-blue-700 w-full mx-auto mb-4 md:mb-0 sm:w-auto sm:ml-4" href="https://www.youtube.com/watch?v=lxudpkKu68g" target="_blank"><i class="fab fa-youtube mr-2"></i> YouTube</a></div>
              <!-- <div><router-link class="btn text-white bg-yellow-300 hover:bg-yellow-200 w-full mx-auto mb-4 md:mb-0 sm:w-auto sm:ml-4" to="/webinare"><i class="fas fa-chalkboard-teacher mr-2"></i> Webináře</router-link></div> -->
            </div>
          </div>

        </div>

        <div>
          <Modal id="modal" ariaLabel="modal-headline" :show="videoModalOpen" @handleClose="videoModalOpen = false">
            <div class="relative pb-9/16">
              <iframe class="absolute w-full h-full" :src="videoModalOpen ? 'https://www.youtube.com/embed/j-SpSKAMsCI' : null" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <!-- <iframe class="absolute w-full h-full" src="https://youtu.be/j-SpSKAMsCI" title="Video" allowFullScreen></iframe> -->
            </div>
          </Modal>

          <Modal id="modal2" ariaLabel="modal-headline2" :show="newsletterModalOpen" @handleClose="newsletterModalOpen = false">
            <div class="sm:col-span-6 md:col-span-3 lg:col-span-3 bg-white p-4 overflow-x-auto text-center">
              <h6 class="text-gray-800 font-medium">Newsletter</h6>
              <p class="text-sm text-gray-600 mb-4">Přihlaste se k odběru novinek</p>
              <iframe id="newsletterFormFrame" name="newsletterFormFrame" style="display:none;"></iframe>
              <form id="newsletterForm" target="newsletterFormFrame" action="https://marketing.construsoft.com/acton/eform/36418/2de86aa1-75bd-420a-96c8-7c6b914c7c82/d-ext-0001">
                <div class="flex flex-wrap">
                  <div v-if="!feedback" class="w-full">
                    <label class="block text-sm sr-only" for="newsletter">Email</label>
                    <div class="relative flex items-center max-w-xs mx-auto">
                      <input id="email" name="email" type="email" class="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm" placeholder="Váš email" v-model="email" required />
                      <button @click="newsletter()" class="absolute inset-0 left-auto" aria-label="Subscribe">
                        <span class="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300" aria-hidden="true"></span>
                        <svg class="w-3 h-3 fill-current text-blue-800 mx-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                        </svg>
                      </button>
                    </div>
                    <!-- Success message -->
                  </div>
                  <p class="text-green-600 text-sm text-center mx-auto" v-if="feedback">{{feedback}}</p>
                </div>
              </form>
            </div>
          </Modal>

          <Modal id="modal3" ariaLabel="modal-headline3" :show="webinarModalopen" @handleClose="webinarModalopen = false">
            <div class="sm:col-span-6 md:col-span-3 lg:col-span-3 bg-white p-4 overflow-x-auto text-center">
              <!-- component -->
              <div class="lg:flex rounded-lg border mt-4">
                <div class="bg-blue-800 lg:w-2/12 py-4 block h-full" style="border-radius: 0.5rem 0 0 0.5rem;">
                  <div class="text-center tracking-wide">
                    <div class="text-white font-bold text-2xl ">19.</div>
                    <div class="text-white font-normal text-xl">Května</div>
                  </div>
                </div>
                <div class="w-full  lg:w-11/12 xl:w-full px-1 bg-white py-5 lg:px-2 lg:py-2 tracking-wide"  style="display: flex; flex-direction: column; justify-content: space-between;">
                  <div class="flex flex-row lg:justify-start justify-center">
                    <div class="text-gray-700 font-medium text-sm text-center lg:text-left px-2">
                      <i class="far fa-clock"></i> 09:00
                    </div>
                    <div class="text-gray-700 font-medium text-sm text-center lg:text-left px-2">
                      Organizér: Martin Maťašovský
                    </div>
                  </div>

                  <div class="font-semibold text-gray-800 text-xl text-center lg:text-left px-2">
                    Trimble Connect – moderní řešení CDE
                  </div>

                  <div class="text-gray-600 font-medium text-sm text-center lg:text-left px-2">
                    <a href="https://www.construsoft.com/cs/trimble-connect-moderni-reseni-cde-19052021" target="_blank">Bezplatná registrace a více informací</a>
                  </div>
                </div>
              </div>

              <div class="lg:flex rounded-lg border mt-4">
                <div class="bg-blue-800 lg:w-2/12 py-4 block h-full" style="border-radius: 0.5rem 0 0 0.5rem;">
                  <div class="text-center tracking-wide">
                    <div class="text-white font-bold text-2xl ">25.</div>
                    <div class="text-white font-normal text-xl">Května</div>
                  </div>
                </div>
                <div class="w-full  lg:w-11/12 xl:w-full px-1 bg-white py-5 lg:px-2 lg:py-2 tracking-wide"  style="display: flex; flex-direction: column; justify-content: space-between;">
                  <div class="flex flex-row lg:justify-start justify-center">
                    <div class="text-gray-700 font-medium text-sm text-center lg:text-left px-2">
                      <i class="far fa-clock"></i> 13:00
                    </div>
                    <div class="text-gray-700 font-medium text-sm text-center lg:text-left px-2">
                      Organizér: Martin Maťašovský
                    </div>
                  </div>

                  <div class="font-semibold text-gray-800 text-xl text-center lg:text-left px-2">
                    Trimble Connect – moderní řešení CDE
                  </div>

                  <div class="text-gray-600 font-medium text-sm text-center lg:text-left px-2">
                    <a href="https://www.construsoft.com/cs/trimble-connect-moderni-reseni-cde-25052021" target="_blank">Bezplatná registrace a více informací</a>
                  </div>
                </div>
              </div>

              <div class="text-center mx-auto mt-2">
                    <a @click.prevent.stop="webinarModalopen = false;" aria-controls="modal_table" class="btn-sm text-white bg-blue-800 hover:bg-blue-700 cursor-pointer">Zavřít</a>
                  </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Modal from './../utils/Modal.vue'

export default {
  name: 'HeroHome',
  components: {
    Modal,
  },
  data: function () {
    return {
      videoModalOpen: false,
      newsletterModalOpen: false,
      webinarModalopen: false,
      feedback: null,
      email: null
    }
  },
  methods: {
    newsletter(){
      if(this.email){
        document.getElementById("newsletterForm").submit()
        this.feedback = "Děkujeme!"
      }
    }
  }
}
</script>

<style lang="scss">
.home-hero-bg:before {
  content: '';
  position: absolute;
  background-image: linear-gradient(to bottom, transparent 66%, #FBFBFB);
  opacity: 1;
  height:100%;
  width:100%;
}

@media screen and (max-width: 480px) {
  .home-hero-bg:before {
    z-index: -1;
  }
}
//
//$s1:"";
//$s2:"";
//$s3:"";
//@for $i from 1 through 300 {
//  $s1: $s1 + random(1000)*0.1vw + " " + random(1000)*0.1vh + " " + 0 + " " + random(50)*-0.01rem + #fff;
//  $s2: $s2 + random(1000)*0.1vw + " " + random(1000)*0.1vh + " " + 0 + " " + random(50)*-0.01rem + #fff;
//  $s3: $s3 + random(1000)*0.1vw + " " + random(1000)*0.1vh + " " + 0 + " " + random(50)*-0.01rem + #fff;
//  @if $i < 300 {
//    $s1: $s1 + ",";
//    $s2: $s2 + ",";
//    $s3: $s3 + ",";
//  }
//}
//
//.snow {
//  border-radius: 50%;
//  opacity: 0.8;
//  position: absolute;
//  top:-100vh;
//  animation-name: fall;
//  animation-timing-function: linear;
//  animation-iteration-count: infinite;
//}
//.layer1 {
//  width: 1.5rem;
//  height: 1.5rem;
//  filter:blur(1.5px);
//  box-shadow: #{$s1};
//  animation-duration: 6s;
//}
//.layer1.a {
//  animation-delay: -3s;
//}
//.layer2 {
//  width: 1.2rem;
//  height: 1.2rem;
//  filter:blur(3px);
//  box-shadow: #{$s2};
//  animation-duration: 8s;
//}
//.layer2.a {
//  animation-delay: -4s;
//}
//.layer3 {
//  width: 0.8rem;
//  height: 0.8rem;
//  filter:blur(6px);
//  box-shadow: #{$s3};
//  animation-duration: 10s;
//}
//.layer3.a {
//  animation-delay: -5s;
//}
//@keyframes fall {
//  100% {transform: translateY(200vh); }
//}
</style>
