<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">

      <!-- Page sections -->
      <HeroHome />
      <HomeBox />
      <FeaturesHome />
      <HomeIndustries />
      <Cta />

    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import HeroHome from './../partials/HeroHome.vue'
import HomeBox from './../partials/HomeBox.vue'
import FeaturesHome from './../partials/FeaturesHome.vue'
import HomeIndustries from './../partials/HomeIndustries.vue'
import Cta from './../partials/Cta.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Home',
  title: 'Kezdőlap',
  components: {
    Header,
    HeroHome,
    HomeBox,
    FeaturesHome,
    HomeIndustries,
    Cta,
    Footer,
  },
}
</script>
