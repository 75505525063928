<template>
  <section class="relative">

    <!-- Section background (needs .relative class on parent and next sibling elements) -->
    <div class="absolute inset-0 bg-gray-100 pointer-events-none" aria-hidden="true"></div>
    <!--<div class="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>-->

    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">

      <div class="pt-12 md:pt-8 pb-12 md:pb-20">

        <div class="max-w-6xl mx-auto text-center pb-6 md:pb-6">
          <h1 class="h2 mb-4">MINDENKI, MINDENNEL,<br>TÖKÉLETESEN ÖSSZEKAPCSOLVA</h1>
          <p class="text-xl text-center md:text-justify text-gray-600">
            A Trimble Connect támogatja a zökkenőmentes adat- és projektinformáció cserét, a csapatirányítást, a hatékony ütközésvezérlést és a jelentéskészítést közvetlenül a modellből. Fejlett eszközökkel lehetővé teszi egyéni metaadatok létrehozását és hozzárendelését a fájlokhoz, valamint egyéni attribútumok hozzárendelését a modellelemekhez. Számos fájlformátummal működik és hatékonyan jeleníti meg a modelleket. Egy hatékony BIM-eszköz, amely lehetővé teszi az off-line munkát is. A Trimble Connect az egyik legjobb BIM-eszköz a projektekben történő együttműködéshez.
          </p>
        </div>

        <!-- style="max-height: 250px; padding-right: 100px; padding-left: 100px" -->
        <div class="flex flex-wrap items-center lg:flex-no-wrap pb-12 md:pb-16">
          <div class="w-full md:w-1/3 pt-12 md:pt-0">
            <img class="mx-auto" :src="require('@/images/tc/tc_illustration.png')" alt="Trimble Connect ilustrace" />
          </div>
          <div class="w-full md:w-2/3 pl-0 md:pl-6 mt-8 md:mt-0">
            <h1 class="h4 text-center md:text-left">Korlátlan hozzáférés</h1>
            <p class="text-xl text-center md:text-justify text-gray-600 mb-4">
              Nincsenek korlátozások, nincsenek modulok. Egyetlen Trimble Connect licenc tartalmazza az összes funkciót. A Trimble Connect bárhol és bármikor elérhető a nagyszerű alkalmazásoknak köszönhetően. Az információkat elérheti a webes alkalmazáson, mobiltelefonon, táblagépen vagy egyszerűen az asztali változaton keresztül. A Trimble Connect segítségével a projektben részt vevők mindegyike ugyanazt a modellt látja, egészen a legapróbb részletekig a kezdetektől fogva a befejezésig. Összehozza az embereket, a technológiát és az információkat, hogy azt nyújtsa, amire és amikor szüksége van.
            </p>
            <div class="flex justify-center md:justify-start">
              <router-link to="/demo" class="btn-sm text-white bg-blue-800 hover:bg-blue-700">
                <span>Ingyenes próba</span>
              </router-link>
              <router-link to="/arak" class="btn-sm text-white bg-gray-800 hover:bg-gray-600 ml-3">
                <span>Árak</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesHome',
  data: function () {
    return {
      tab: '1',
    }
  },
}
</script>
