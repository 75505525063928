<template>
  <section class="relative">

    <!-- Section background (needs .relative class on parent and next sibling elements) -->
    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-12 md:pt-20 pb-12 md:pb-20">

        <div class="max-w-3xl mx-auto text-center pb-6 md:pb-6">
          <h2 class="h2 mb-4">A Trimble Connect szakma-specifikus előnyöket biztosít</h2>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <a @click.prevent="tab=0" class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
              <div><div class="text-normal font-medium mb-1">Építészek</div></div>
              <svg class="w-4 h-4 fill-current text-blue-800 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" /></svg>
            </a>
            <a @click.prevent="tab=1" class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
              <div><div class="text-normal font-medium mb-1">Tervezők</div></div>
              <svg class="w-4 h-4 fill-current text-blue-800 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" /></svg>
            </a>
            <a @click.prevent="tab=2" class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
              <div><div class="text-normal font-medium mb-1">Fővállalkozók</div></div>
              <svg class="w-4 h-4 fill-current text-blue-800 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" /></svg>
            </a>
            <a @click.prevent="tab=3" class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4" href="#0">
              <div><div class="text-normal font-medium mb-1">Alvállalkozók</div></div>
              <svg class="w-4 h-4 fill-current text-blue-800 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" /></svg>
            </a>
            <a @click.prevent="tab=4" class="flex justify-between items-center p-4 rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg" href="#0">
              <div><div class="text-normal font-medium mb-1">Döntéshozók és befektetők</div></div>
              <svg class="w-4 h-4 fill-current text-blue-800 flex-shrink-0 ml-6" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" /></svg>
            </a>
          </div>

          <div>
            <transition enter-active-class="transition ease-in-out duration-700 transform order-first" enter-class="opacity-0 translate-y-16" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in-out duration-300 transform absolute" leave-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-16" >
              <div v-if="tab==0" class="flex h-full p-4 text-center rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4">
                <div class="m-auto">
                  <h2 class="h2 mb-4">Építészek</h2>
                  <p class="mb-6">Nincs szüksége más programra a modellek, rajzok és egyéb adatok megosztásához. A Trimble Connect a projekt minden szakaszában hozzáférést biztosít az információkhoz az összes érdekelt fél számára. A 2D-s és 3D-s részletek másodpercek alatt elérhetők. A fájlok táblagépen vagy hololensben akár vegyes valóságban is megjeleníthetők.</p>
                  <a @click.prevent.stop='tableModalOpen = true; modalIndustries("Együttműködés és jobb eredmények", "Egyetlen és hiteles információforrás", "A modern felhasználó barát felhőalapú rendszernek köszönhetően minden információ egyértelműen elérhető az összes érdekelt fél számára.", "Hatékonyabb együttműködés", "Tervezzen, vizsgálja felül és kommentálja a modelleket, rajzokat és adatokat valós időben. Használja ki a tervezőeszközével (Tekla Structures, SketchUp, Revit stb.) való élő kapcsolatot, és takarítson meg időt az egyes dokumentumok mozgatásával.", "Az információvesztés kiküszöbölése", "Tartson naprakészen minden információt, és biztosítsa a felhasználók számára a hozzáférést. Lehetővé teszi a felhasználók számára, hogy a projektet az általuk kívánt részletességgel vizualizálják. Amikor csak szükséges.", "https://www.construsoft.com/sites/default/files/CZE/tc.cz/architekti.png", "A Trimble Connect megkönnyíti és hatékonyabbá teszi a BIM folyamatokkal való együttműködést.")' aria-controls="modal_table" class="btn-sm text-white bg-blue-800 hover:bg-blue-700 cursor-pointer">További információk</a>
                </div>
              </div>
            </transition>

            <transition enter-active-class="transition ease-in-out duration-700 transform order-first" enter-class="opacity-0 translate-y-16" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in-out duration-300 transform absolute" leave-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-16" >
              <div v-if="tab==1" class="flex h-full p-4 text-center rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4">
                <div class="m-auto">             
                  <h2 class="h2 mb-4">Tervezők</h2>
                  <p class="mb-6">A modellek és rajzok zökkenőmentes megosztása, megtekintése és kommentálása valós időben történik. Információkhoz való hozzáférés az irodában és a helyszínen egyaránt lehetséges. Egyszerűsítse munkáját, és nyújtsa be a projekteket időben, minimális átdolgozással.</p>
                  <a @click.prevent.stop='tableModalOpen = true; modalIndustries( "Egyszerűsítse munkáját a koncepciótól a kivitelezésig. Együttműködés és jobb eredmények alacsonyabb költségek mellett.", "Egyetlen és hiteles információforrás", "Egy modern, felhőalapú, nagyon felhasználóbarát rendszernek köszönhetően minden információ egyértelműen elérhető az összes érdekelt fél számára.", "Hatékonyabb együttműködés", "Tervezzen, vizsgálja felül és kommentálja a modelleket, rajzokat és adatokat valós időben. Bármikor és bárhol, amikor és amikor csak szüksége van rá. Az irodában, a gyártásban és a helyszínen is.", "Projektek időben és ütemezetten", "Tartsa naprakészen az összes információt, és biztosítsa a felhasználók számára a hozzáférést. Lehetővé teszi a felhasználók számára, hogy részletes 3D modellekkel és rajzokkal dolgozzanak. A Trimble Connect segítségével gyors visszajelzéseket, emlékeztetőket vagy megjegyzéseket kapcsolhatnak a modellekhez.", "https://www.construsoft.com/sites/default/files/CZE/tc.cz/projektanti.png", "A Trimble Connect megkönnyíti és hatékonyabbá teszi a BIM folyamatokkal való együttműködést a projektekben.")'  aria-controls="modal_table" class="btn-sm text-white bg-blue-800 hover:bg-blue-700 cursor-pointer">További információk</a>
                </div>
              </div>
            </transition>

            <transition enter-active-class="transition ease-in-out duration-700 transform order-first" enter-class="opacity-0 translate-y-16" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in-out duration-300 transform absolute" leave-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-16" >
              <div v-if="tab==2" class="flex h-full p-4 text-center rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4">
                <div class="m-auto">
                  <h2 class="h2 mb-4">Fővállalkozók</h2>
                  <p class="mb-6">A könnyen elérhető és pontos információk kiküszöbölik a találgatásokat és a pótmunkákat, amelyek megakadályozzák a projektek határidőn- és a költségvetésen belüli befejezését. Adjon hozzáférést minden munkatársának a naprakész és részletes információkhoz.</p>
                  <a @click.prevent.stop='tableModalOpen = true; modalIndustries( "Minden projekt időben és a költségvetési kereten belül tarthatja", "Tökéletes kommunikáció", "Korszerű alkalmazásunkkal bármikor és bárhol hozzáférhet az adatokhoz. Az irodában, a gyártásban és a helyszínen. Ez lehetővé teszi a hatékonyabb kommunikációt.", "Modern munkafolyamatok", "Könnyedén felülvizsgálhatja, kommentálhatja és megoszthatja a modelleket, rajzokat és egyéb adatokat, hogy csökkentse a későbbi utómunkálatokat vagy az ütemezési késedelmeket. Biztosítsa a magas színvonalú, pozitív eredményeket minden egyes projektje esetében.", "Hozzon helyes döntéseket", "A Trimble Connect alkalmazás megfelelő információkat nyújt a projektről és annak egyes elemeiről, teljes áttekintést nyújt a feladatokról és egyéb tevékenységekről, így Ön időben és pontosan tud döntéseket hozni.", "https://www.construsoft.com/sites/default/files/CZE/tc.cz/gendodavatelé.png", "A Trimble Connect megkönnyíti a BIM folyamatokkal való együttműködést és növeli a projektek hatékonyságát.")' aria-controls="modal_table" class="btn-sm text-white bg-blue-800 hover:bg-blue-700 cursor-pointer">További információk</a>
                </div>
              </div>
            </transition>

            <transition enter-active-class="transition ease-in-out duration-700 transform order-first" enter-class="opacity-0 translate-y-16" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in-out duration-300 transform absolute" leave-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-16" >
              <div v-if="tab==3" class="flex h-full p-4 text-center rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4">
                <div class="m-auto">
                  <h2 class="h2 mb-4">Alvállalkozók</h2>
                  <p class="mb-6">Az akták és a jogszabályok folyamatosan változnak az egyes projekteken belül, ami késedelmekhez és megnövekedett költségekhez vezethet. A Trimble Connect segítségével mindig naprakész fájlokkal és információkkal, fejlett dokumentum- és rajzkezelési lehetőségekkel rendelkezik, mindezt egy felhasználóbarát alkalmazásban. Ez megkönnyíti a natív fájlok megnyitását és kezelését is.</p>
                  <a @click.prevent.stop='tableModalOpen = true; modalIndustries( "A legmodernebb mobil megoldás az építkezéshez. Együttműködés és jobb eredmények", "Tökéletes kommunikáció", "Modern alkalmazásunkkal bármikor és bárhol hozzáférhet az adatokhoz. Az offline munkavégzést támogató mobilalkalmazással pedig a legnehezebb körülmények között is dolgozhat az adatokkal. A Trimble Connect átfogó CDE megoldásnak köszönhetően hatékonyan és korszerűen kommunikálhat.", "Integráció más szoftverekkel", "Csatlakoztassa meglévő eszközeit a Trimble Connect segítségével, és használja ki ezt a teljesítményt. Így mindig naprakész projektinformációk állnak majd rendelkezésre.", "Modern munkafolyamatok", "Könnyen felülvizsgálhatja, kommentálhatja és megoszthatja a modelleket, rajzokat és egyéb adatokat, hogy csökkentse a későbbi utómunkálatokat vagy az ütemezési késedelmeket. Biztosítsa a minőségi eredményeket projektjeihez.", "https://www.construsoft.com/sites/default/files/CZE/tc.cz/subdodavatelé.jpg", "A Trimble Connect megkönnyíti a BIM folyamatokkal való együttműködést és hatékonyabbá teszi a projekteket.")' aria-controls="modal_table" class="btn-sm text-white bg-blue-800 hover:bg-blue-700 cursor-pointer">További információk</a>
                </div>
              </div>
            </transition>

            <transition enter-active-class="transition ease-in-out duration-700 transform order-first" enter-class="opacity-0 translate-y-16" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in-out duration-300 transform absolute" leave-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-16" >
              <div v-if="tab==4" class="flex h-full p-4 text-center rounded border border-gray-200 transition duration-300 ease-in-out bg-white shadow-md hover:shadow-lg mb-4">
                <div class="m-auto">
                  <h2 class="h2 mb-4">Döntéshozók és befektetők</h2>
                  <p class="mb-6">A folyamat különböző szakaszainak és az összes érdekelt félnek a tökéletes összekapcsolásával követheti nyomon a projektek előrehaladását. A valós idejű feladatokhoz, jelentésekhez és egyéb információkhoz való hozzáférés segít a kockázatok kiküszöbölésében és hatékony kezelésében, valamint a helyes döntések meghozatalában.</p>
                  <a @click.prevent.stop='tableModalOpen = true; modalIndustries( "A mindig naprakész, átlátható és könnyen hozzáférhető információk segítségével teljes áttekintést kaphat projektjeiről. Együttműködés és jobb eredménye.", "Nagy hatékonyság", "Vonja be az összes érdekelt felet a projektfolyamatba, és mindenki számára biztosítsa a szükséges információkhoz való hozzáférést. A naprakész adatok bárhol és bármikor mindenki számára elérhetőek lesznek.", "Növelje a vállalat ismertségét", "A legmodernebb technológia használata a közös projektekben egy másik szintre emeli Önt. Átlátható adatok, hatékony kommunikáció és pontos megbízások.", "A helyes döntések", "A Trimble Connect alkalmazás megfelelő információkat nyújt a projektről és annak különböző részeiről, teljes áttekintést nyújt a feladatokról és egyéb tevékenységekről, így időben és helyesen hozhat döntéseket. És ami a legfontosabb, mindig teljes mértékben Ön irányítja a projektet.", "https://www.construsoft.com/sites/default/files/CZE/tc.cz/investoři.jpg", "A Trimble Connect megkönnyíti és hatékonyabbá teszi a BIM folyamatokkal való együttműködést.")' aria-controls="modal_table" class="btn-sm text-white bg-blue-800 hover:bg-blue-700 cursor-pointer">További információk</a>
                </div>
              </div>
            </transition>

          </div>

          <div>
            <Modal id="modal_table" ariaLabel="modal-headline" :show="tableModalOpen" @handleClose="tableModalOpen = false">
              <div class="relative text-black">
                <div class="overflow-x-auto p-4 shadow-lg bg-white rounded">
                  <!--modalIndustries(t0, t1, d1, t2, d2, t3, d3, img)-->
                  <p class="h3 mb-2 text-left md:text-center">{{t0}}</p>
                  <p class="mb-6 text-xl text-left md:text-center">{{huh}}</p>
                  
                  <div class="flex flex-wrap lg:flex-no-wrap mb-6">
                    <div v-if="img" class="w-full md:w-1/2" style="background-size: cover; border-radius: 5px; box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;" :style="'background-image: url('+img+')'" ></div>
                    <div class="w-full md:w-1/2 pl-0 md:pl-6 mt-8 md:mt-0">
                      <h1 class="h4">{{t1}}</h1>
                      <p class="text-lg text-gray-600 mb-6">{{d1}}</p>
                      <h1 class="h4">{{t2}}</h1>
                      <p class="text-lg text-gray-600 mb-6">{{d2}}</p>
                      <h1 class="h4">{{t3}}</h1>
                      <p class="text-lg text-gray-600 mb-6">{{d3}}</p>
                    </div>
                  </div>
      
                  <div class="text-center mx-auto mb-4">
                    <a @click.prevent.stop="tableModalOpen = false;" aria-controls="modal_table" class="btn-sm text-white bg-blue-800 hover:bg-blue-700 cursor-pointer">Bezárás</a>
                  </div>
                </div>
              </div>
            </Modal>
          </div>

        </div>

      </div>
    </div>
  </section>
</template>

<script>
import Modal from './../utils/Modal.vue'

export default {
  name: 'HomeIndustries',
  components: {
    Modal,
  },
  data: function () {
    return {
      tab: 0,
      tableModalOpen: false,
      selected: "arch",
      whyTC: ["3D značky", "Historie projektu", "Posouvání modelů v prohlížeči", "Přiřazování úkolů", "Změna barvy objektů", "Změna barvy celého modelu", "Kombinace a zobrazení vybraných modelů", "Komentování úkolů", "Správa viditelnosti celého modelu", "Správa viditelnosti objektů v modelech", "Tvorba projektu", "Tvorba a správa složek", "Tvorba a správa verzí dokumentu", "Tvorba skupin objektů", "Tvorba úkolů", "Definování vlastních reportů", "Výměna úkolů pomocí BCF 1.0", "Export reportů", "Prohlížení souborů", "Mřížka", "Vlastnosti elementů modelu", "Správa oprávněnosti přístupu a notifikace uživatelů", "Správa uživatelů a skupin uživatelů", "Funkce měření ", "Filtr objektů v modelu", "Kontrola kolizí", "Ukládání pohledů", "Ukládání obrázků v komentářích", "Dočasný off-line úložný prostor", "Prohlížení/komentování kolizí"]
    , Arch: [0, 1, 4, 5, 7, 8, 9, 13, 15, 17, 18, 19, 20, 24, 28, 29]
    , Arch_PC: [!0, !1, !0, !0, !0, !0, !0, !0, !0, !0, !0, !0, !1, !0, !0, !1, !1, !1, !0, !0, !0, !1, !1, !0, !0, !0, !0, !0, !0, !0]
    , Arch_Mobile: [!1, !1, !1, !0, !1, !1, !0, !0, !0, !0, !1, !1, !1, !1, !0, !1, !1, !1, !0, !1, !0, !1, !1, !0, !1, !1, !0, !1, !0, !1]
    , Arch_Web: [!0, !0, !0, !0, !1, !1, !0, !0, !0, !0, !0, !0, !0, !1, !0, !0, !0, !0, !0, !1, !0, !0, !0, !0, !1, !0, !0, !1, !1, !0]
    , eng: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 28, 29]
    , eng_PC: [!0, !1, !0, !0, !0, !0, !0, !0, !0, !0, !1, !0, !0, !1, !1, !1, !0, !0, !0, !1, !1, !0, !0, !0, !0, !0, !0]
    , eng_Mobile: [!1, !1, !1, !0, !1, !1, !0, !0, !0, !0, !1, !1, !0, !1, !1, !1, !0, !1, !0, !1, !1, !0, !1, !1, !0, !0, !1]
    , eng_Web: [!0, !0, !0, !0, !1, !1, !0, !0, !0, !0, !0, !1, !0, !0, !0, !0, !0, !1, !0, !0, !0, !0, !1, !0, !0, !1, !0]
    , genCon: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]
    , genCon_PC: [!0, !1, !0, !0, !0, !0, !0, !0, !0, !0, !0, !0, !0, !0, !1, !1, !1, !0, !0, !0, !1, !1, !0, !0, !0, !0, !0, !0, !0]
    , genCon_Mobile: [!1, !1, !1, !0, !1, !1, !0, !0, !0, !0, !1, !1, !1, !0, !1, !1, !1, !0, !1, !0, !1, !1, !0, !1, !1, !0, !1, !0, !1]
    , genCon_Web: [!0, !0, !0, !0, !1, !1, !0, !0, !0, !0, !0, !0, !1, !0, !0, !0, !0, !0, !1, !0, !0, !0, !0, !1, !0, !0, !1, !1, !0]
    , subCon: [0, 1, 4, 5, 7, 8, 9, 13, 17, 19, 20, 24, 28]
    , subCon_PC: [!0, !1, !0, !0, !0, !0, !0, !0, !1, !0, !0, !0, !0, !0]
    , subCon_Mobile: [!1, !1, !1, !1, !0, !0, !0, !1, !1, !0, !1, !0, !1, !0]
    , subCon_Web: [!0, !0, !1, !1, !0, !0, !0, !1, !0, !0, !1, !0, !1, !1]
    , owner: [0, 1, 4, 5, 7, 8, 9, 11, 13, 15, 17, 18, 19, 20, 24, 28, 29]
    , owner_PC: [!0, !1, !0, !0, !0, !0, !0, !0, !0, !1, !1, !0, !0, !0, !0, !0, !0]
    , owner_Mobile: [!1, !1, !1, !1, !0, !0, !0, !1, !1, !1, !1, !0, !1, !0, !1, !0, !1]
    , owner_Web: [!0, !0, !1, !1, !0, !0, !0, !0, !1, !0, !0, !0, !1, !0, !1, !1, !0]
    , t0: null, t1: null, d1: null, t2: null, d2: null, t3: null, d3: null, img: null, huh: null
    }
  },
  methods: {
    modalIndustries(t0, t1, d1, t2, d2, t3, d3, img, huh){
      this.t0 = t0
      this.t1 = t1
      this.d1 = d1
      this.t2 = t2
      this.d2 = d2
      this.t3 = t3
      this.d3 = d3
      this.img = img
      this.huh = huh
    },
  }
}
</script>