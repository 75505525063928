<template>
  <section class="relative">
    <div class="absolute inset-0 bg-gray-100 pointer-events-none" aria-hidden="true"></div>
    <div class="transform -translate-y-12 mx-auto max-w-6xl px-4 sm:px-6" style="translateY(-50%)">
          <!-- Section background (needs .relative class on parent and next sibling elements) -->
      <div class="grid grid-cols-1 md:grid-cols-4 gap-4">

        <div class="items-center p-6 bg-white rounded shadow-xl text-center transform transition duration-500 hover:scale-105 ease-in-out hover:bg-gray-100">
          <i class="fa-solid fa-laptop-mobile h4 mb-2 bg-blue-800 p-4 rounded-full text-white"></i>
          <p class="font-bold">Több platformon működik</p>
          <div style="height: 2px;" class="bg-blue-800 my-2 mx-4"></div>
          <p>Munkavégzés több eszközön on—line és off-line környezetben.</p>
        </div>

        <div class="items-center p-6 bg-white rounded shadow-xl text-center transform transition duration-500 hover:scale-105 ease-in-out hover:bg-gray-100">
          <i class="fa-solid fa-cube h4 mb-2 bg-blue-800 p-4 rounded-full text-white"></i>
          <p class="font-bold">Modern BIM megtekintő</p>
          <div style="height: 2px;" class="bg-blue-800 my-2 mx-4"></div>
          <p>Korszerű megjelenítés 2D rajzok és 3D modellek többféle formátum kezelésével.</p>
        </div>
        
        <div class="items-center p-6 bg-white rounded shadow-xl text-center transform transition duration-500 hover:scale-105 ease-in-out hover:bg-gray-100">
          <i class="fa-solid fa-calendar-check h4 mb-2 bg-blue-800 p-4 rounded-full text-white"></i>
          <p class="font-bold">BIM szabványok</p>
          <div style="height: 2px;" class="bg-blue-800 my-2 mx-4"></div>
          <p>Megfelel az ISO-szabványoknak és a BIM módszertannak.</p>
        </div>
        
        <div class="items-center p-6 bg-white rounded shadow-xl text-center transform transition duration-500 hover:scale-105 ease-in-out hover:bg-gray-100">
          <i class="fa-solid fa-headset h4 mb-2 bg-blue-800 p-4 rounded-full text-white"></i>
          <p class="font-bold">Ügyfélszolgálat</p>
          <div style="height: 2px;" class="bg-blue-800 my-2 mx-4"></div>
          <p>Magyar nyelvű ügyfélszolgálat és támogatás saját licencekhez.</p>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeBox',
}
</script>